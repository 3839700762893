// src/pages/Episodes.jsx
import React, { useState, useEffect, useRef } from 'react';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import { 
  Play, 
  Pause, 
  Clock, 
  Calendar, 
  Search, 
  X,
  ChevronRight
} from 'lucide-react';
import AudioPlayer from '../components/AudioPlayer';
import { podcastConfig } from '../config/podcast';
import LoadingSkeleton from '../components/LoadingSkeleton';
import { SITE_CONFIG } from '../config/site';

const stripHtml = (html) => {
  if (!html) return '';
  const tmp = document.createElement('DIV');
  tmp.innerHTML = html;
  return tmp.textContent || tmp.innerText || '';
};

const formatDate = (dateString) => {
  return new Date(dateString).toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'long',
    day: 'numeric'
  });
};

const formatTime = (timeInSeconds) => {
  if (!timeInSeconds || isNaN(timeInSeconds)) return '00:00';
  const minutes = Math.floor(timeInSeconds / 60);
  const seconds = Math.floor(timeInSeconds % 60);
  return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
};

const parseDuration = (duration) => {
  if (!duration) return { display: '00:00', seconds: 0 };

  let seconds = 0;
  let display = '00:00';

  if (duration.includes(':')) {
    const parts = duration.split(':').map(Number);
    if (parts.length === 2) {
      seconds = parts[0] * 60 + parts[1];
    } else if (parts.length === 3) {
      seconds = parts[0] * 3600 + parts[1] * 60 + parts[2];
    }
    display = duration;
  } else {
    seconds = parseInt(duration, 10);
    if (!isNaN(seconds)) {
      display = formatTime(seconds);
    }
  }

  return { display, seconds };
};

const CategoryFilter = ({ categories, selectedCategories, onSelectCategory, onClearFilters }) => (
  <div className="flex flex-wrap items-center gap-2">
    {categories.map(category => (
      <button
        key={category}
        onClick={() => onSelectCategory(category)}
        className={`px-3 py-1.5 rounded-full text-sm font-medium transition-colors ${
          selectedCategories.includes(category)
            ? 'bg-[#3384ff] text-white'
            : 'bg-[#2e3244] text-white/70 hover:bg-[#353a4f]'
        }`}
      >
        {category}
      </button>
    ))}
    {selectedCategories.length > 0 && (
      <button
        onClick={onClearFilters}
        className="px-3 py-1.5 rounded-full text-sm font-medium bg-[#2e3244] text-white/70 hover:bg-[#353a4f] flex items-center gap-1"
      >
        <X size={14} />
        Clear Filters
      </button>
    )}
  </div>
);

const CompactHero = ({ latestEpisode }) => {
  if (!latestEpisode) return null;

  return (
    <div className="relative bg-[#000000] overflow-hidden">
      <div className="absolute inset-0">
        <div className="absolute inset-0 bg-gradient-to-br from-[#3384ff]/5 to-[#000000]/5" />
      </div>
      
      <div className="relative max-w-7xl bg-[#000000] mx-auto px-6 py-12">
        <div className="grid lg:grid-cols-2 gap-8 items-center">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6 }}
          >
            <Link 
            to={`/episode/${latestEpisode.episodeNumber}`}
            className="inline-flex items-center..."
          >
              Latest Episode #{latestEpisode.episodeNumber}
              <ChevronRight className="w-4 h-4 ml-1" />
            </Link>
            
            <h1 className="text-3xl font-bold text-white mb-4">
              <Link 
                to={`/episode/${latestEpisode.episodeNumber}`}
                className="hover:text-[#3384ff] transition-colors"
              >
                {latestEpisode.title}
              </Link>
            </h1>
            <p className="text-white/70 mb-6 line-clamp-2">{stripHtml(latestEpisode.description)}</p>
            <AudioPlayer 
              src={latestEpisode.audioUrl} 
              title={latestEpisode.title}
              duration={latestEpisode.durationInSeconds}
            />
          </motion.div>

          <motion.div
            initial={{ opacity: 0, scale: 0.95 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.6, delay: 0.2 }}
            className="relative hidden lg:block"
          >
            <Link to={`/episode/${latestEpisode.episodeNumber}`} className="block">
              <div className="aspect-video rounded-xl overflow-hidden bg-[#2e3244]">
                <img
                  src={`${SITE_CONFIG.baseUrl}/podcast-cover.jpg`}
                  alt={latestEpisode.title}
                  className="w-full h-full object-cover transition-transform duration-300 hover:scale-105"
                />
                <div className="absolute inset-0 bg-gradient-to-t from-black/50 to-transparent" />
              </div>
            </Link>
          </motion.div>
        </div>
      </div>
    </div>
  );
};

const EpisodeListItem = ({ episode }) => {
  const [isPlaying, setIsPlaying] = useState(false);

  return (
    <motion.div 
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      className="group bg-[#2e3244] rounded-xl p-6 hover:bg-[#353a4f] transition-all duration-300"
    >
      <div className="flex gap-6">
        <Link 
          to={`/episode/${episode.episodeNumber}`}
          className="relative w-40 h-40 flex-shrink-0"
        >
          <img
            src={`${SITE_CONFIG.baseUrl}/podcast-cover.jpg`}
            alt={episode.title}
            className="w-full h-full object-cover rounded-lg transition-transform duration-300 group-hover:scale-105"
          />
          <button
            onClick={(e) => {
              e.preventDefault();
              setIsPlaying(!isPlaying);
            }}
            className="absolute inset-0 flex items-center justify-center bg-black/50 opacity-0 group-hover:opacity-100 transition-opacity rounded-lg"
          >
            {isPlaying ? (
              <Pause className="w-12 h-12 text-white" />
            ) : (
              <Play className="w-12 h-12 text-white ml-1" />
            )}
          </button>
        </Link>

        <div className="flex-1 min-w-0">
          <div className="flex items-center gap-4 mb-2">
            <Link 
              to={`/episode/${episode.episodeNumber}`}
              className="text-[#3384ff] text-sm font-medium hover:text-[#3384ff]/80 transition-colors"
            >
              Episode {episode.episodeNumber}
            </Link>
            <div className="flex items-center gap-2 text-white/50 text-sm">
              <Calendar className="w-4 h-4" />
              {formatDate(episode.pubDate)}
            </div>
            <div className="flex items-center gap-2 text-white/50 text-sm">
              <Clock className="w-4 h-4" />
              {episode.duration}
            </div>
          </div>

          <h3 className="text-xl font-bold text-white mb-2 truncate">
            <Link 
              to={`/episode/${episode.episodeNumber}`}
              className="hover:text-[#3384ff] transition-colors"
            >
              {episode.title}
            </Link>
          </h3>
          <p className="text-white/70 text-sm line-clamp-2 mb-4">
            {stripHtml(episode.description)}
          </p>

          {episode.categories?.length > 0 && (
            <div className="flex flex-wrap gap-2 mb-4">
              {episode.categories.map((category, idx) => (
                <span
                  key={idx}
                  className="px-2 py-1 bg-[#000000] rounded-full text-xs text-white/50"
                >
                  {category}
                </span>
              ))}
            </div>
          )}

          {isPlaying && (
            <div className="bg-[#000000]/50 rounded-lg p-4 mt-4">
              <AudioPlayer 
                src={episode.audioUrl} 
                title={episode.title}
                duration={episode.durationInSeconds}
              />
            </div>
          )}
        </div>
      </div>
    </motion.div>
  );
};

const fetchPagedEpisodes = async (pageNum, totalItems) => {
  try {
    if (!totalItems) {
      const feedResponse = await fetch(podcastConfig.feedUrl);
      const feedText = await feedResponse.text();
      const parser = new DOMParser();
      const feedDoc = parser.parseFromString(feedText, "text/xml");
      totalItems = feedDoc.getElementsByTagName('item').length;
    }

    const ITEMS_PER_PAGE = 10;
    const offset = (pageNum - 1) * ITEMS_PER_PAGE;
    
    const response = await fetch(
      `https://api.rss2json.com/v1/api.json?rss_url=${encodeURIComponent(podcastConfig.feedUrl)}&api_key=${podcastConfig.rss2jsonApiKey}&count=${ITEMS_PER_PAGE}&offset=${offset}`
    );
    
    const data = await response.json();
    
    if (!response.ok || !data.status === 'ok') {
      throw new Error('Failed to fetch episodes');
    }

    const processedEpisodes = data.items.map((item, index) => {
      const absoluteIndex = offset + index;
      const episodeNumber = totalItems - absoluteIndex;
      const { display: duration, seconds: durationInSeconds } = parseDuration(item.duration);

      return {
        id: episodeNumber,
        title: item.title,
        description: item.description,
        pubDate: item.pubDate,
        image: item.thumbnail || podcastConfig.defaultImage,
        audioUrl: item.enclosure?.link,
        duration: duration,
        durationInSeconds: durationInSeconds,
        episodeNumber: episodeNumber,
        guid: item.guid,
        categories: extractCategories(item.title, item.description)
      };
    });

    return {
      episodes: processedEpisodes,
      totalItems: totalItems,
      hasMore: offset + ITEMS_PER_PAGE < totalItems
    };
  } catch (error) {
    console.error('Error fetching episodes:', error);
    throw error;
  }
};

const extractCategories = (title, description) => {
  const text = `${title} ${description}`.toLowerCase();
  const categories = [];
  
  if (text.includes('tech') || text.includes('technology')) categories.push('Technology');
  if (text.includes('business') || text.includes('startup')) categories.push('Business');
  if (text.includes('interview')) categories.push('Interview');
  if (text.includes('news') || text.includes('update')) categories.push('News');
  if (text.includes('tesla')) categories.push('Tesla');
  if (text.includes('spacex') || text.includes('space')) categories.push('SpaceX');
  
  return categories;
};

const Episodes = () => {
  const [episodes, setEpisodes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [availableCategories, setAvailableCategories] = useState([]);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [totalItems, setTotalItems] = useState(0);
  const loadMoreRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting && hasMore && !loading) {
          setPage(prev => prev + 1);
        }
      },
      { threshold: 0.1 }
    );

    if (loadMoreRef.current) {
      observer.observe(loadMoreRef.current);
    }

    return () => observer.disconnect();
  }, [hasMore, loading]);

  useEffect(() => {
    const loadEpisodes = async () => {
      try {
        setLoading(true);
        const { episodes: newEpisodes, totalItems: total, hasMore: more } = 
          await fetchPagedEpisodes(page, totalItems);
        
        setTotalItems(total);

        const filteredEpisodes = newEpisodes.filter(episode => {
          const matchesSearch = !searchTerm || 
            episode.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
            stripHtml(episode.description).toLowerCase().includes(searchTerm.toLowerCase());
          
          const matchesCategories = selectedCategories.length === 0 ||
            selectedCategories.some(cat => episode.categories.includes(cat));
          
          return matchesSearch && matchesCategories;
        });

        if (page === 1) {
          setEpisodes(filteredEpisodes);
          const allCategories = new Set();
          filteredEpisodes.forEach(ep => {
            ep.categories.forEach(cat => allCategories.add(cat));
          });
          setAvailableCategories(Array.from(allCategories).sort());
        } else {
          setEpisodes(prev => [...prev, ...filteredEpisodes]);
        }

        setHasMore(more);
      } catch (error) {
        setError('Failed to load episodes');
      } finally {
        setLoading(false);
      }
    };

    loadEpisodes();
  }, [page, searchTerm, selectedCategories]);

  useEffect(() => {
    setPage(1);
    setEpisodes([]);
  }, [searchTerm, selectedCategories]);

  if (error) {
      return (
        <div className="min-h-screen bg-[#000000] flex items-center justify-center px-4">
          <div className="text-center">
            <h2 className="text-2xl font-bold text-white mb-4">Failed to load episodes</h2>
            <p className="text-white/70 mb-8">{error}</p>
            <button
              onClick={() => window.location.reload()}
              className="px-6 py-3 bg-[#3384ff] text-white rounded-xl hover:bg-[#3384ff]/90 transition-colors"
            >
              Try Again
            </button>
          </div>
        </div>
      );
    }

    if (loading && page === 1) {
      return <LoadingSkeleton />;
    }

    return (
      <div className="min-h-screen bg-[#000000]">
        <CompactHero latestEpisode={episodes[0]} />
        
        <div className="sticky top-0 bg-[#000000]/80 backdrop-blur-lg z-10 border-b border-white/10">
          <div className="max-w-7xl mx-auto px-6 py-4 space-y-4">
            <div className="flex items-center gap-4">
              <div className="relative flex-1">
                <Search className="absolute left-4 top-1/2 -translate-y-1/2 text-white/50" size={20} />
                <input
                  type="text"
                  placeholder="Search episodes..."
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  className="w-full bg-[#2e3244] border border-white/10 rounded-xl pl-12 pr-4 py-3 text-white placeholder-white/30 focus:outline-none focus:ring-2 focus:ring-[#3384ff]"
                />
              </div>
            </div>

            <CategoryFilter
              categories={availableCategories}
              selectedCategories={selectedCategories}
              onSelectCategory={(category) => {
                setSelectedCategories(prev => 
                  prev.includes(category)
                    ? prev.filter(c => c !== category)
                    : [...prev, category]
                );
              }}
              onClearFilters={() => setSelectedCategories([])}
            />
          </div>
        </div>

        <div className="max-w-7xl mx-auto px-6 py-12">
          <div className="space-y-6">
            {episodes.map((episode) => (
              <EpisodeListItem key={episode.id} episode={episode} />
            ))}
          </div>

          {hasMore && (
            <div ref={loadMoreRef} className="py-12 text-center">
              {loading ? (
                <div className="w-8 h-8 border-2 border-[#3384ff] border-t-transparent rounded-full animate-spin mx-auto" />
              ) : (
                <div className="h-8" />
              )}
            </div>
          )}

          {!loading && episodes.length === 0 && (
            <div className="py-12 text-center text-white/50">
              No episodes found matching your search.
            </div>
          )}

          {episodes.length > 0 && !hasMore && (
            <div className="py-12 text-center text-white/50">
              You've reached the end of the episodes list.
            </div>
          )}
        </div>
      </div>
    );
  };

export default Episodes;