import { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import Footer from '../Footer';
import { 
  Search,
  Bell,
  Menu,
  X,
  ExternalLink
} from 'lucide-react';

const NavLink = ({ to, children, isActive }) => (
  <Link
    to={to}
    className={`px-4 py-2 rounded-lg transition-all duration-200 text-sm font-medium
      ${isActive 
        ? 'bg-[#3384ff]/10 text-[#3384ff]' 
        : 'text-white/70 hover:bg-[#2e3244]'
      }`}
  >
    {children}
  </Link>
);

const NavigationBar = () => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const location = useLocation();

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      setIsScrolled(scrollPosition > 80);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const navigationItems = [
    { to: '/', label: 'Home' },
    { to: '/episodes', label: 'Episodes' },
    { to: '/topics', label: 'Topics' },
    { to: '/about', label: 'About' }
  ];

  return (
    <header className="fixed top-0 left-0 right-0 z-50 flex justify-center transition-all duration-300">
      <div className={`mt-[30px] transition-all duration-300 ease-in-out ${
        isScrolled ? 'mt-4 w-1/2' : 'w-[85%]'
      }`}>
        <nav className="bg-black/90 backdrop-blur-lg border border-white/10 rounded-full">
          <div className="px-6">
            <div className="flex items-center justify-between h-16">
              {/* Logo */}
              <Link to="/" className="flex items-center">
                <img
                  src="/emp-logo-nav.png"
                  alt="Elon Musk Podcast"
                  className={`transition-all duration-300 ${
                    isScrolled ? 'h-8' : 'h-10'
                  } w-auto hover:scale-105`}
                />
              </Link>

              {/* Desktop Navigation */}
              <div className="hidden md:flex items-center gap-2">
                {navigationItems.map((item) => (
                  <NavLink
                    key={item.to}
                    to={item.to}
                    isActive={location.pathname === item.to}
                  >
                    {item.label}
                  </NavLink>
                ))}
              </div>

              {/* Action Buttons */}
              <div className="hidden md:flex items-center gap-4">
                <button className="p-2 rounded-lg text-white/70 hover:bg-[#2e3244] transition-colors">
                  <Search size={20} />
                </button>
                <button className="p-2 rounded-lg text-white/70 hover:bg-[#2e3244] transition-colors relative">
                  <Bell size={20} />
                  <span className="absolute top-1 right-1 w-2 h-2 bg-[#3384ff] rounded-full" />
                </button>
                <div className="h-6 w-px bg-white/10" />
                <a
                  href="https://open.spotify.com/show/49vTLjDIZufDkpxp2vPPtk"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="flex items-center gap-2 px-4 py-2 bg-[#3384ff] text-white rounded-lg hover:bg-[#3384ff]/90 transition-colors text-sm font-medium"
                >
                  Listen Now
                  <ExternalLink size={14} />
                </a>
              </div>

              {/* Mobile Menu Button */}
              <button
                onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
                className="md:hidden p-2 rounded-lg text-white/70 hover:bg-[#2e3244] transition-colors"
              >
                {isMobileMenuOpen ? <X size={24} /> : <Menu size={24} />}
              </button>
            </div>
          </div>

          {/* Mobile Navigation */}
          {isMobileMenuOpen && (
            <div className="md:hidden py-4 px-4 border-t border-white/10 bg-black rounded-b-3xl">
              <div className="space-y-1">
                {navigationItems.map((item) => (
                  <Link
                    key={item.to}
                    to={item.to}
                    onClick={() => setIsMobileMenuOpen(false)}
                    className={`block px-4 py-2 rounded-lg text-sm font-medium transition-colors
                      ${location.pathname === item.to
                        ? 'bg-[#3384ff]/10 text-[#3384ff]'
                        : 'text-white/70 hover:bg-[#2e3244]'
                      }`}
                  >
                    {item.label}
                  </Link>
                ))}
              </div>
            </div>
          )}
        </nav>
      </div>
    </header>
  );
};

const MainLayout = ({ children }) => {
  return (
    <div className="min-h-screen bg-[#1f2333] text-white">
      <NavigationBar />
      <main className="pt-20 min-h-[calc(100vh-5rem)] bg-[#000]">
        <div className="h-full flex justify-center">
          <div className="w-full max-w-screen-2xl px-4 md:px-6 lg:px-0 py-0">
            <div className="max-w-[1400px] mx-auto">
              {children}
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </div>
  );
};

export default MainLayout;