import { useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import { Star, ArrowRight, BadgeCheck, Users, Rocket, Brain } from 'lucide-react';
import ReactGA from 'react-ga4';

const expertiseAreas = [
  {
    title: "SpaceX Coverage",
    description: "Year-long embedded reporting at SpaceX's Starbase facility, providing firsthand insights into Starship development and launch operations.",
    icon: Rocket,
    badge: "Primary Focus"
  },
  {
    title: "Technical Analysis",
    description: "Deep technical knowledge of spacecraft systems, manufacturing processes, and autonomous technologies across multiple industries.",
    icon: Brain,
    badge: "Expert"
  },
  {
    title: "Industry Network",
    description: "Extensive network of contacts across SpaceX, Tesla, and the broader aerospace and EV industries.",
    icon: Users,
    badge: "Connected"
  }
];

const ExpertAnalysisSection = ({ episodes }) => {
  const sectionRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          ReactGA.event({
            category: 'Section View',
            action: 'Expert Analysis Section Visible'
          });
        }
      },
      { threshold: 0.2 }
    );

    if (sectionRef.current) {
      observer.observe(sectionRef.current);
    }

    return () => observer.disconnect();
  }, []);

  return (
    <section ref={sectionRef} className="relative py-24">
      {/* Background with gradient and pattern */}
      <div className="absolute inset-0 bg-gradient-to-b from-[#0F2027] via-[#203A43] to-[#1A1A1A]">
        <div className="absolute inset-0 bg-[url('/circuit-pattern.svg')] opacity-5" />
      </div>

      {/* Decorative elements */}
      <div className="absolute inset-0 overflow-hidden">
        <div className="absolute top-0 right-0 w-[500px] h-[500px] bg-cyan-500/10 rounded-full blur-3xl" />
        <div className="absolute bottom-0 left-0 w-[500px] h-[500px] bg-emerald-500/10 rounded-full blur-3xl" />
      </div>

      {/* Content */}
      <div className="relative max-w-7xl mx-auto px-6">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          className="mb-12"
        >
          <h2 className="text-3xl font-bold text-white mb-4">
            Expert Analysis & Commentary
          </h2>
          <p className="text-cyan-200/70 max-w-2xl">
            With over four years of dedicated coverage and firsthand experience at SpaceX's Starbase, 
            Wil Waldon brings unparalleled expertise to every episode.
          </p>
        </motion.div>

        <div className="grid md:grid-cols-2 gap-8">
          {/* Host Bio Column */}
          <motion.div
            initial={{ opacity: 0, x: -20 }}
            whileInView={{ opacity: 1, x: 0 }}
            viewport={{ once: true }}
            className="bg-white/[0.03] backdrop-blur-sm border border-cyan-500/10 rounded-xl overflow-hidden"
          >
            <div className="relative aspect-video">
              <img
                src="/wil-starbase.jpg"
                alt="Wil at SpaceX Starbase"
                className="w-full h-full object-cover"
              />
              <div className="absolute inset-0 bg-gradient-to-t from-black to-transparent" />
              <div className="absolute bottom-4 left-4 right-4">
                <div className="flex items-center gap-2 mb-2">
                  <BadgeCheck className="w-5 h-5 text-cyan-400" />
                  <span className="text-sm text-cyan-200 font-medium">Verified Industry Expert</span>
                </div>
                <h3 className="text-xl font-bold text-white">Wil Waldon</h3>
                <p className="text-cyan-200/70 text-sm">Host & Lead Analyst</p>
              </div>
            </div>
            <div className="p-8">
              <div className="space-y-4 text-zinc-300">
                <p>
                  Having spent a year embedded at SpaceX's Starbase facility, Wil brings firsthand 
                  insights into the development of humanity's most advanced spacecraft.
                </p>
                <p>
                  His dedicated coverage of SpaceX, Tesla, and other innovative companies combines 
                  technical expertise with a passionate understanding of their revolutionary impact.
                </p>
              </div>
            </div>
          </motion.div>

          {/* Expertise Areas Column */}
          <motion.div
            initial={{ opacity: 0, x: 20 }}
            whileInView={{ opacity: 1, x: 0 }}
            viewport={{ once: true }}
            className="space-y-6"
          >
            {expertiseAreas.map((area, index) => (
              <div
                key={area.title}
                className="bg-white/[0.03] backdrop-blur-sm border border-emerald-500/10 rounded-xl p-6 hover:bg-white/[0.05] transition-colors"
              >
                <div className="flex items-start gap-4">
                  <div className="w-12 h-12 rounded-lg bg-emerald-500/10 flex items-center justify-center shrink-0">
                    <area.icon className="w-6 h-6 text-emerald-400" />
                  </div>
                  <div>
                    <div className="flex items-center gap-3 mb-2">
                      <h3 className="text-lg font-semibold text-white">{area.title}</h3>
                      <span className="px-2 py-1 bg-emerald-500/10 rounded-full text-emerald-400 text-xs font-medium">
                        {area.badge}
                      </span>
                    </div>
                    <p className="text-zinc-300 text-sm">{area.description}</p>
                  </div>
                </div>
              </div>
            ))}

            <Link
              to="/about"
              className="inline-flex items-center gap-2 text-emerald-400 hover:text-emerald-300 transition-colors group"
            >
              Learn more about Wil
              <ArrowRight className="w-4 h-4 group-hover:translate-x-1 transition-transform" />
            </Link>
          </motion.div>
        </div>

        {/* Latest Episodes Preview */}
        {episodes && episodes.length > 0 && (
          <div className="mt-12">
            <h3 className="text-xl font-bold text-white mb-6">Latest Analysis</h3>
            <div className="grid md:grid-cols-2 gap-6">
              {episodes.slice(0, 2).map((episode) => (
                <Link
                  key={episode.id}
                  to={`/episode/${episode.id}`}
                  className="bg-white/[0.03] hover:bg-white/[0.05] backdrop-blur-sm border border-cyan-500/10 rounded-xl p-6 transition-colors"
                >
                  <h4 className="text-lg font-semibold text-white mb-2">{episode.title}</h4>
                  <p className="text-zinc-400 text-sm line-clamp-2 mb-4">{episode.description}</p>
                  <div className="flex items-center gap-2 text-sm text-cyan-400">
                    <span>Listen Now</span>
                    <ArrowRight className="w-4 h-4" />
                  </div>
                </Link>
              ))}
            </div>
          </div>
        )}
      </div>

      {/* Section divider */}
      <div className="absolute inset-x-0 bottom-0 h-px bg-gradient-to-r from-transparent via-cyan-500/20 to-transparent" />
    </section>
  );
};

export default ExpertAnalysisSection;