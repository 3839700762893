// TopicSection.jsx
import { useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import { ArrowRight } from 'lucide-react';
import ReactGA from 'react-ga4';
import { Helmet } from 'react-helmet-async';

const topics = [
  {
    title: "Tesla Innovation & Technology",
    description: "In-depth coverage of Tesla's electric vehicle innovations, including manufacturing breakthroughs, Full Self-Driving development, Gigafactory operations, and next-generation battery technology.",
    link: "/topics/tesla",
    icon: "electric_car",
    keywords: "Tesla, electric vehicles, FSD, Gigafactory, EV batteries, automotive innovation",
    subTopics: ["Model 3/Y Production", "FSD Beta", "4680 Batteries", "Giga Manufacturing"]
  },
  {
    title: "SpaceX Space Exploration",
    description: "Comprehensive analysis of SpaceX's revolutionary space programs, including Starship development, Starlink satellite network, Mars colonization plans, and reusable rocket technology.",
    link: "/topics/spacex",
    icon: "rocket_launch",
    keywords: "SpaceX, Starship, Starlink, Mars colonization, reusable rockets, space exploration",
    subTopics: ["Starship Updates", "Launch Operations", "Starlink Network", "Mars Planning"]
  },
  {
    title: "Neuralink Brain Interface",
    description: "Latest developments in Neuralink's brain-computer interface technology, including clinical trials, neural engineering breakthroughs, and implications for treating neurological conditions.",
    link: "/topics/neuralink",
    icon: "psychology",
    keywords: "Neuralink, brain-computer interface, neural engineering, medical technology",
    subTopics: ["Clinical Trials", "Device Development", "Medical Applications", "Neural Tech"]
  },
  {
    title: "The Boring Company Infrastructure",
    description: "Analysis of The Boring Company's tunnel construction technology, Loop transportation systems, and innovative solutions for urban transit and infrastructure development.",
    link: "/topics/boring-company",
    icon: "tunnel",
    keywords: "Boring Company, tunnel construction, Loop system, urban transit, infrastructure",
    subTopics: ["Vegas Loop", "Tunnel Tech", "Transit Systems", "Infrastructure"]
  },
  {
    title: "X (Twitter) Platform Evolution",
    description: "Tracking the transformation of Twitter into X, covering platform developments, payment integration, content monetization, and the vision for a global digital town square.",
    link: "/topics/x-platform",
    icon: "public",
    keywords: "X, Twitter, social media, digital payments, content monetization",
    subTopics: ["Platform Changes", "Payment Features", "Creator Tools", "User Experience"]
  },
  {
    title: "Sustainable Energy Future",
    description: "Exploring the intersection of Tesla's energy products, solar technology, and grid-scale storage solutions shaping the transition to sustainable energy.",
    link: "/topics/energy",
    icon: "bolt",
    keywords: "sustainable energy, Tesla Energy, solar power, Powerwall, grid storage",
    subTopics: ["Solar Roof", "Powerwall", "Grid Storage", "Energy Software"]
  }
];

const TopicCard = ({ topic, index }) => {
  return (
    <motion.article
      initial={{ opacity: 0, y: 20 }}
      whileInView={{ opacity: 1, y: 0 }}
      viewport={{ once: true }}
      transition={{ duration: 0.5, delay: index * 0.1 }}
      className="group relative bg-white/[0.03] hover:bg-white/[0.05] backdrop-blur-sm border border-purple-500/10 rounded-xl p-8 transition-all duration-300"
    >
      <div className="absolute inset-0 bg-gradient-to-br from-purple-500/5 to-transparent opacity-0 group-hover:opacity-100 transition-opacity duration-300 rounded-xl" />
      
      <div className="relative h-full flex flex-col">
        <div className="w-12 h-12 rounded-lg bg-purple-500/10 flex items-center justify-center mb-6">
          <span className="material-icons text-purple-400">{topic.icon}</span>
        </div>
        <h3 className="text-xl font-bold text-white mb-4">
          {topic.title}
        </h3>
        <p className="text-purple-200/70 mb-6 flex-grow">
          {topic.description}
        </p>
        <div className="mb-6">
          <h4 className="text-sm font-medium text-purple-300 mb-2">Featured Coverage:</h4>
          <div className="flex flex-wrap gap-2">
            {topic.subTopics.map((subTopic) => (
              <span
                key={subTopic}
                className="px-2 py-1 bg-purple-500/10 rounded-full text-purple-300 text-xs"
              >
                {subTopic}
              </span>
            ))}
          </div>
        </div>
        <Link 
          to={topic.link}
          className="inline-flex items-center gap-2 text-purple-400 hover:text-purple-300 transition-colors group/link"
          aria-label={`Explore ${topic.title} coverage`}
        >
          Explore Coverage 
          <ArrowRight className="w-4 h-4 group-hover/link:translate-x-1 transition-transform" />
        </Link>
      </div>
    </motion.article>
  );
};

const TopicSection = () => {
  return (
    <section className="relative py-24">

      {/* Background with gradient and pattern */}
      <div className="absolute inset-0 bg-gradient-to-br from-[#1A0F2E] via-[#2C1810] to-black">
        <div className="absolute inset-0 bg-[url('/hexagon-pattern.svg')] opacity-5" />
      </div>

      {/* Decorative elements */}
      <div className="absolute inset-0 overflow-hidden">
        <div className="absolute top-0 right-0 w-[500px] h-[500px] bg-purple-500/10 rounded-full blur-3xl" />
        <div className="absolute bottom-0 left-0 w-[500px] h-[500px] bg-orange-500/10 rounded-full blur-3xl" />
      </div>

      {/* Content */}
      <div className="relative max-w-7xl mx-auto px-6">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          className="mb-12"
        >
          <h1 className="text-3xl font-bold text-white mb-4">
            Featured Topics & Expert Analysis
          </h1>
          <p className="text-purple-200/70 max-w-2xl">
           We give you daily updates and clear breakdowns on the technologies building tomorrow - from SpaceX's path to Mars and Tesla's electric vehicles to Neuralink's brain interfaces and sustainable energy solutions.
          </p>
        </motion.div>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {topics.map((topic, index) => (
            <TopicCard key={topic.title} topic={topic} index={index} />
          ))}
        </div>
      </div>

      {/* Section divider */}
      <div className="absolute inset-x-0 bottom-0 h-px bg-gradient-to-r from-transparent via-purple-500/20 to-transparent" />
    </section>
  );
};

export default TopicSection;