import React, { useRef, useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { Mail, ArrowRight, CheckCircle, XCircle } from 'lucide-react';
import ReactGA from 'react-ga4';

const NewsletterSection = () => {
  const [email, setEmail] = useState('');
  const [status, setStatus] = useState('idle');
  const [errorMessage, setErrorMessage] = useState('');
  const sectionRef = useRef(null);
  const inputRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          ReactGA.event({
            category: 'Section View',
            action: 'Newsletter Section Visible',
            label: 'Newsletter Form'
          });
        }
      },
      { threshold: 0.2 }
    );

    if (sectionRef.current) {
      observer.observe(sectionRef.current);
    }

    return () => observer.disconnect();
  }, []);

  const validateEmail = (email) => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (!email) {
      setErrorMessage('Please enter your email address');
      setStatus('error');
      return;
    }

    if (!validateEmail(email)) {
      setErrorMessage('Please enter a valid email address');
      setStatus('error');
      return;
    }

    setStatus('loading');
    ReactGA.event({
      category: 'Newsletter',
      action: 'Subscription Attempt',
      label: 'Newsletter Form Submit'
    });

    try {
      // Replace with your actual newsletter subscription API endpoint
      // const response = await fetch('/api/subscribe', {
      //   method: 'POST',
      //   headers: { 'Content-Type': 'application/json' },
      //   body: JSON.stringify({ email })
      // });

      // Simulated API call
      await new Promise(resolve => setTimeout(resolve, 1000));
      
      setStatus('success');
      setEmail('');
      ReactGA.event({
        category: 'Newsletter',
        action: 'Subscription Success',
        label: 'Newsletter Signup Complete'
      });
    } catch (error) {
      setStatus('error');
      setErrorMessage('Something went wrong. Please try again.');
      ReactGA.event({
        category: 'Newsletter',
        action: 'Subscription Error',
        label: error.message
      });
    }
  };

  return (
    <section ref={sectionRef} className="relative py-24">
      <div className="absolute inset-0 bg-black">
        <div className="absolute inset-0 bg-gradient-to-br from-white/[0.02] to-transparent" />
      </div>
      
      <div className="relative max-w-4xl mx-auto px-6">
        <motion.div 
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          className="bg-white/[0.03] backdrop-blur-sm border border-white/10 rounded-2xl p-12"
        >
          <div className="flex flex-col items-center text-center">
            <div className="w-16 h-16 rounded-full bg-white/5 flex items-center justify-center mb-6">
              <Mail className="w-8 h-8 text-white" />
            </div>
            
            <h2 className="text-3xl font-bold text-white mb-4 tracking-tight">
              Never Miss an Episode
            </h2>
            
            <p className="text-zinc-400 mb-8 max-w-lg">
              Subscribe to our newsletter for exclusive insights, early access to episodes, 
              and updates on the latest developments in technology and innovation.
            </p>

            <form onSubmit={handleSubmit} className="w-full max-w-md">
              <div className="relative">
                <input
                  ref={inputRef}
                  type="email"
                  value={email}
                  onChange={(e) => {
                    setEmail(e.target.value);
                    if (status === 'error') setStatus('idle');
                  }}
                  placeholder="Enter your email address"
                  className={`w-full px-6 py-4 bg-black/40 rounded-xl text-white placeholder-zinc-500 
                    focus:outline-none focus:ring-2 transition-all duration-300 border
                    ${status === 'error' 
                      ? 'border-red-500/50 focus:ring-red-500/20' 
                      : 'border-white/10 focus:ring-white/20'}`}
                  disabled={status === 'loading' || status === 'success'}
                />
                
                <button
                  type="submit"
                  disabled={status === 'loading' || status === 'success'}
                  className={`absolute right-2 top-1/2 -translate-y-1/2 px-4 py-2 
                    rounded-lg flex items-center gap-2 transition-all duration-300
                    ${status === 'success' 
                      ? 'bg-green-500/20 text-green-500'
                      : status === 'error'
                        ? 'bg-red-500/20 text-red-500'
                        : 'bg-white text-black hover:bg-white/90'}`}
                >
                  {status === 'loading' ? (
                    <div className="w-6 h-6 border-2 border-white/20 border-t-white rounded-full animate-spin" />
                  ) : status === 'success' ? (
                    <CheckCircle className="w-6 h-6" />
                  ) : status === 'error' ? (
                    <XCircle className="w-6 h-6" />
                  ) : (
                    <>
                      Subscribe
                      <ArrowRight className="w-4 h-4" />
                    </>
                  )}
                </button>
              </div>
              
              {status === 'error' && (
                <motion.p 
                  initial={{ opacity: 0, y: -10 }}
                  animate={{ opacity: 1, y: 0 }}
                  className="text-red-500 text-sm mt-2"
                >
                  {errorMessage}
                </motion.p>
              )}
              
              {status === 'success' && (
                <motion.p 
                  initial={{ opacity: 0, y: -10 }}
                  animate={{ opacity: 1, y: 0 }}
                  className="text-green-500 text-sm mt-2"
                >
                  Thanks for subscribing! Check your email to confirm.
                </motion.p>
              )}
            </form>

            <p className="text-zinc-500 text-sm mt-6">
              We respect your privacy. Unsubscribe at any time.
            </p>
          </div>
        </motion.div>
      </div>

      <div className="absolute inset-0 overflow-hidden pointer-events-none">
        <div className="absolute -top-40 -right-40 w-80 h-80 bg-white/[0.02] rounded-full blur-3xl" />
        <div className="absolute -bottom-40 -left-40 w-80 h-80 bg-white/[0.02] rounded-full blur-3xl" />
      </div>
    </section>
  );
};

export default React.memo(NewsletterSection);