// src/components/EpisodeCard.jsx
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Play, Pause, Share2, BookmarkPlus, BookmarkCheck } from 'lucide-react';
import AudioPlayer from './AudioPlayer';
import { stripHtmlTags, truncateText } from '../utils/textUtils';

const EpisodeCard = ({ episode }) => {
  const [isBookmarked, setIsBookmarked] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const [showShareMenu, setShowShareMenu] = useState(false);

  const handlePlayToggle = () => {
    setIsPlaying(!isPlaying);
  };

  const handleBookmark = () => {
    setIsBookmarked(!isBookmarked);
  };

  // Clean and truncate the description
  const cleanDescription = truncateText(stripHtmlTags(episode.description), 150);

  return (
    <div className="group relative bg-zinc-900 rounded-xl overflow-hidden transition-transform duration-300 hover:-translate-y-1">
      {/* Image Section */}
      <div className="relative aspect-[4/3] overflow-hidden">
        <img
          src={`${SITE_CONFIG.baseUrl}/podcast-cover.jpg`}
          alt={episode.title}
          className="w-full h-full object-cover transition-transform duration-300 group-hover:scale-105"
        />
        <div className="absolute inset-0 bg-gradient-to-t from-zinc-900 to-transparent opacity-90" />
        
        {/* Play Button Overlay */}
        <button
          onClick={handlePlayToggle}
          className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 w-14 h-14 bg-orange-500 rounded-full flex items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity duration-300 hover:bg-orange-600"
        >
          {isPlaying ? (
            <Pause className="text-white" size={24} />
          ) : (
            <Play className="text-white translate-x-0.5" size={24} />
          )}
        </button>

        {/* Episode Number Tag */}
        <div className="absolute top-4 left-4 px-3 py-1 bg-zinc-900/80 backdrop-blur-sm rounded-full">
          <span className="text-sm font-medium text-orange-500">
            Episode {episode.episodeNumber}
          </span>
        </div>
      </div>

      {/* Content Section */}
      <div className="p-6">
        <div className="flex flex-wrap gap-2 mb-3">
          {episode.tags?.map((tag, index) => (
            <span
              key={index}
              className="px-2 py-1 bg-zinc-800 rounded-full text-xs font-medium text-gray-300"
            >
              {tag}
            </span>
          ))}
        </div>

        <Link to={`/episode/${episode.episodeNumber}`}>
          <h3 className="text-xl font-bold text-white mb-2 line-clamp-2 hover:text-orange-500 transition-colors">
            {episode.title}
          </h3>
        </Link>

        <p className="text-gray-400 text-sm mb-4 line-clamp-2">
          {cleanDescription}
        </p>

        {/* Bottom Actions */}
        <div className="flex items-center justify-between">
          <span className="text-sm text-gray-500">
            {new Date(episode.pubDate).toLocaleDateString()}
          </span>

          <div className="flex items-center gap-3">
            <button
              onClick={() => setShowShareMenu(!showShareMenu)}
              className="p-2 text-gray-400 hover:text-white transition-colors"
              title="Share episode"
            >
              <Share2 size={20} />
            </button>

            <button
              onClick={handleBookmark}
              className={`p-2 transition-colors ${
                isBookmarked ? 'text-orange-500' : 'text-gray-400 hover:text-white'
              }`}
              title={isBookmarked ? 'Remove bookmark' : 'Add bookmark'}
            >
              {isBookmarked ? (
                <BookmarkCheck size={20} />
              ) : (
                <BookmarkPlus size={20} />
              )}
            </button>
          </div>
        </div>

        {/* Audio Player */}
        {isPlaying && (
          <div className="mt-4 pt-4 border-t border-zinc-800">
            <AudioPlayer 
              src={episode.audioUrl} 
              title={stripHtmlTags(episode.title)}
              onClose={() => setIsPlaying(false)}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default EpisodeCard;