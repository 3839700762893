// src/pages/Topics.jsx
import React from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import { ArrowRight, Car, Rocket, Brain, Building, Globe } from 'lucide-react';
import { Helmet } from 'react-helmet-async';

const topics = [
  {
    title: "Tesla Innovation & Technology",
    description: "In-depth coverage of Tesla's electric vehicle innovations, including manufacturing breakthroughs, Full Self-Driving development, Gigafactory operations, and next-generation battery technology.",
    slug: "tesla",
    color: "bg-blue-500/10 text-blue-400",
    Icon: Car,
    subTopics: ["Model 3/Y Production", "FSD Beta", "4680 Batteries", "Giga Manufacturing"]
  },
  {
    title: "SpaceX Space Exploration",
    description: "Comprehensive analysis of SpaceX's revolutionary space programs, including Starship development, Starlink satellite network, Mars colonization plans, and reusable rocket technology.",
    slug: "spacex",
    color: "bg-red-500/10 text-red-400",
    Icon: Rocket,
    subTopics: ["Starship Updates", "Launch Operations", "Starlink Network", "Mars Planning"]
  },
  {
    title: "Neuralink Brain Interface",
    description: "Latest developments in Neuralink's brain-computer interface technology, including clinical trials, neural engineering breakthroughs, and implications for treating neurological conditions.",
    slug: "neuralink",
    color: "bg-purple-500/10 text-purple-400",
    Icon: Brain,
    subTopics: ["Clinical Trials", "Device Development", "Medical Applications", "Neural Tech"]
  },
  {
    title: "The Boring Company Infrastructure",
    description: "Analysis of The Boring Company's tunnel construction technology, Loop transportation systems, and innovative solutions for urban transit and infrastructure development.",
    slug: "boring-company",
    color: "bg-green-500/10 text-green-400",
    Icon: Building,
    subTopics: ["Vegas Loop", "Tunnel Tech", "Transit Systems", "Infrastructure"]
  },
  {
    title: "X Platform Evolution",
    description: "Tracking the transformation of Twitter into X, covering platform developments, payment integration, content monetization, and the vision for a global digital town square.",
    slug: "x-platform",
    color: "bg-yellow-500/10 text-yellow-400",
    Icon: Globe,
    subTopics: ["Platform Changes", "Payment Features", "Creator Tools", "User Experience"]
  }
];

const TopicCard = ({ topic, index }) => {
  const { Icon } = topic;
  
  return (
    <motion.article
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5, delay: index * 0.1 }}
      className="group relative bg-[#2e3244] rounded-xl overflow-hidden"
    >
      <div className="absolute inset-0 bg-gradient-to-br from-[#3384ff]/10 to-transparent opacity-0 group-hover:opacity-100 transition-opacity duration-300" />
      
      <div className="relative p-8">
        <div className="flex items-start gap-6">
          <div className={`w-16 h-16 rounded-xl ${topic.color} flex items-center justify-center flex-shrink-0`}>
            <Icon size={32} />
          </div>
          
          <div className="flex-1">
            <h2 className="text-2xl font-bold text-white mb-3">{topic.title}</h2>
            <p className="text-white/70 mb-6 line-clamp-2">{topic.description}</p>
            
            <div className="mb-6">
              <h3 className="text-sm font-medium text-white/50 mb-3">Featured Coverage:</h3>
              <div className="flex flex-wrap gap-2">
                {topic.subTopics.map((subTopic) => (
                  <span
                    key={subTopic}
                    className="px-3 py-1 bg-[#1f2333] rounded-full text-white/70 text-sm"
                  >
                    {subTopic}
                  </span>
                ))}
              </div>
            </div>

            <Link 
              to={`/topics/${topic.slug}`}
              className="inline-flex items-center gap-2 text-[#3384ff] hover:text-[#3384ff]/80 transition-colors group/link"
            >
              Browse Episodes
              <ArrowRight className="w-4 h-4 group-hover/link:translate-x-1 transition-transform" />
            </Link>
          </div>
        </div>
      </div>
    </motion.article>
  );
};

const TopicsPage = () => {
  return (
    <div className="min-h-screen bg-[#1f2333]">
      <Helmet>
        <title>Topics - The Elon Musk Podcast</title>
        <meta name="description" content="Explore our comprehensive coverage of Tesla, SpaceX, Neuralink, and more." />
      </Helmet>

      <div className="max-w-7xl mx-auto px-6 py-20">
        <div className="max-w-2xl mb-16">
          <h1 className="text-4xl font-bold text-white mb-6">
            Featured Topics & Analysis
          </h1>
          <p className="text-white/70 text-lg">
            Dive deep into our comprehensive coverage of innovative technologies and companies shaping the future. From electric vehicles and space exploration to neural technology and sustainable energy.
          </p>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          {topics.map((topic, index) => (
            <TopicCard key={topic.slug} topic={topic} index={index} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default TopicsPage;