import React, { useState, useEffect, Suspense, lazy } from 'react';
import { useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { LazyMotion, domAnimation } from 'framer-motion';
import ReactGA from 'react-ga4';

// Component Imports
import HeroSection from '../components/home/HeroSection';
import StatisticsSection from '../components/home/StatisticsSection';
import TopicSection from '../components/home/TopicSection';
import ExpertAnalysisSection from '../components/home/ExpertAnalysisSection';
import PlatformSection from '../components/home/PlatformSection';
import NewsletterSection from '../components/home/NewsletterSection';
import SeoContentSection from '../components/home/SeoContentSection';
import LoadingSkeleton from '../components/LoadingSkeleton';

// Lazy loaded components
const AudioPlayer = lazy(() => import('../components/AudioPlayer'));
const FeaturedEpisodes = lazy(() => import('../components/FeaturedEpisodes'));

// Config Import
import { podcastConfig } from '../config/podcast';

// Site Configuration
const SITE_CONFIG = {
  title: 'The Elon Musk Podcast',
  description: 'Your premier source for in-depth analysis and discussions about Tesla, SpaceX, Neuralink, and the visionary behind them. Join us as we explore the companies and innovations shaping humanity\'s future.',
  baseUrl: 'https://elonmuskpodcast.com',
  socialLinks: {
    twitter: 'https://twitter.com/elonmuskpodcast',
    linkedin: 'https://linkedin.com/company/elonmuskpodcast'
  }
};

const getStructuredData = (latestEpisode, totalEpisodes) => ({
  "@context": "https://schema.org",
  "@type": "PodcastSeries",
  "name": SITE_CONFIG.title,
  "description": SITE_CONFIG.description,
  "url": SITE_CONFIG.baseUrl,
  "author": {
    "@type": "Person",
    "name": "Warren Redlich",
    "url": `${SITE_CONFIG.baseUrl}/about`
  },
  "numberOfEpisodes": totalEpisodes,
  "image": `${SITE_CONFIG.baseUrl}/podcast-cover.jpg`,
  "publication": [{
    "@type": "BroadcastEvent",
    "isLiveBroadcast": false,
    "startDate": latestEpisode?.pubDate,
    "name": latestEpisode?.title
  }]
});

// Data fetching function
const fetchPodcastFeed = async (config) => {
  try {
    const feedResponse = await fetch(config.feedUrl);
    const feedText = await feedResponse.text();
    const parser = new DOMParser();
    const feedDoc = parser.parseFromString(feedText, "text/xml");
    const totalEpisodes = feedDoc.getElementsByTagName('item').length;

    const response = await fetch(
      `https://api.rss2json.com/v1/api.json?rss_url=${encodeURIComponent(config.feedUrl)}&api_key=${config.rss2jsonApiKey}&count=${config.episodesPerPage}`
    );
    const data = await response.json();
    
    if (!response.ok) {
      throw new Error('Failed to fetch podcast feed');
    }

    return data.items.map((item, index) => ({
      id: index + 1,
      title: item.title,
      description: item.description,
      pubDate: item.pubDate,
      image: item.thumbnail || item.enclosure?.thumbnail || config.defaultImage,
      audioUrl: item.enclosure?.link,
      duration: item.duration || "00:00",
      episodeNumber: totalEpisodes - index,
      link: item.link
    }));
  } catch (error) {
    console.error('Error fetching podcast feed:', error);
    throw error;
  }
};

export default function Home() {
  const [episodes, setEpisodes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const location = useLocation();

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname });
    const loadEpisodes = async () => {
      try {
        setLoading(true);
        const episodesData = await fetchPodcastFeed(podcastConfig);
        setEpisodes(episodesData);
        setError(null);
      } catch (error) {
        console.error('Failed to load episodes:', error);
        setError(error.message);
        setEpisodes([]);
      } finally {
        setLoading(false);
      }
    };

    loadEpisodes();
  }, []);

  if (loading) {
    return <LoadingSkeleton />;
  }

  if (error) {
    return (
      <div className="min-h-screen bg-black flex items-center justify-center px-4">
        <div className="text-center">
          <h2 className="text-2xl font-bold text-white mb-4">Failed to load episodes</h2>
          <p className="text-zinc-400 mb-8">{error}</p>
          <button
            onClick={() => window.location.reload()}
            className="px-6 py-3 bg-white text-black rounded-xl hover:bg-white/90 transition-colors"
          >
            Try Again
          </button>
        </div>
      </div>
    );
  }

  return (
    <LazyMotion features={domAnimation}>
      <div className="min-h-screen bg-black">
        <Helmet>
          <title>{SITE_CONFIG.title}</title>
          <meta name="description" content={SITE_CONFIG.description} />
          <link rel="canonical" href={`${SITE_CONFIG.baseUrl}${location.pathname}`} />
          <meta property="og:title" content={SITE_CONFIG.title} />
          <meta property="og:description" content={SITE_CONFIG.description} />
          <meta property="og:image" content={`${SITE_CONFIG.baseUrl}/og-image.jpg`} />
          <meta property="og:url" content={`${SITE_CONFIG.baseUrl}${location.pathname}`} />
          <meta property="og:type" content="website" />
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:site" content="@elonmuskpodcast" />
          <script type="application/ld+json">
            {JSON.stringify(getStructuredData(episodes[0], episodes.length))}
          </script>
        </Helmet>

        <Suspense fallback={<LoadingSkeleton />}>
          <HeroSection episode={episodes[0]} />
          <div className="relative">
            <StatisticsSection totalEpisodes={episodes.length} />
            <TopicSection />
            <FeaturedEpisodes episodes={episodes} />
            <ExpertAnalysisSection episodes={episodes} />
            <PlatformSection />
            <NewsletterSection />
            <SeoContentSection />
          </div>
        </Suspense>
      </div>
    </LazyMotion>
  );
}