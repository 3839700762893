import { useRef, useEffect } from 'react';
import { motion } from 'framer-motion';
import ReactGA from 'react-ga4';

const platforms = [
  { 
    name: 'Apple Podcasts',
    url: '#',
    icon: 'podcasts',
    color: 'group-hover:text-[#9933FF]',
    description: 'Listen on Apple Podcasts',
    backgroundColor: 'bg-[#9933FF]/10'
  },
  { 
    name: 'Spotify',
    url: '#',
    icon: 'music_note',
    color: 'group-hover:text-[#1DB954]',
    description: 'Stream on Spotify',
    backgroundColor: 'bg-[#1DB954]/10'
  },
  { 
    name: 'Google Podcasts',
    url: '#',
    icon: 'podcasts',
    color: 'group-hover:text-[#4285F4]',
    description: 'Listen on Google Podcasts',
    backgroundColor: 'bg-[#4285F4]/10'
  },
  { 
    name: 'RSS Feed',
    url: '#',
    icon: 'rss_feed',
    color: 'group-hover:text-[#FFA500]',
    description: 'Subscribe via RSS',
    backgroundColor: 'bg-[#FFA500]/10'
  }
];

const PlatformCard = ({ platform, index }) => {
  const cardRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          ReactGA.event({
            category: 'Section View',
            action: 'Platform Card Visible',
            label: platform.name
          });
        }
      },
      { threshold: 0.5 }
    );

    if (cardRef.current) {
      observer.observe(cardRef.current);
    }

    return () => observer.disconnect();
  }, [platform.name]);

  const handleClick = () => {
    ReactGA.event({
      category: 'Navigation',
      action: 'Platform Click',
      label: platform.name
    });
  };

  return (
    <motion.a
      ref={cardRef}
      href={platform.url}
      target="_blank"
      rel="noopener noreferrer"
      onClick={handleClick}
      initial={{ opacity: 0, y: 20 }}
      whileInView={{ opacity: 1, y: 0 }}
      viewport={{ once: true }}
      transition={{ duration: 0.5, delay: index * 0.1 }}
      className="group relative bg-white/[0.03] backdrop-blur-sm border border-white/10 rounded-xl p-8 hover:bg-white/[0.05] transition-all duration-300"
    >
      <div className="relative flex flex-col items-center text-center gap-4">
        <div className={`w-16 h-16 rounded-full ${platform.backgroundColor} flex items-center justify-center group-hover:scale-110 transition-transform duration-300`}>
          <span className={`material-icons text-3xl text-white transition-colors ${platform.color}`}>
            {platform.icon}
          </span>
        </div>
        <div>
          <h3 className="text-white font-medium mb-1 group-hover:text-white/90 transition-colors">
            {platform.name}
          </h3>
          <p className="text-zinc-400 text-sm">
            {platform.description}
          </p>
        </div>
      </div>

      <div className="absolute inset-0 bg-gradient-to-br from-white/[0.03] to-transparent opacity-0 group-hover:opacity-100 transition-opacity duration-300 rounded-xl pointer-events-none" />
    </motion.a>
  );
};

const PlatformSection = () => {
  const sectionRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          ReactGA.event({
            category: 'Section View',
            action: 'Platform Section Visible',
            label: 'Platforms Overview'
          });
        }
      },
      { threshold: 0.2 }
    );

    if (sectionRef.current) {
      observer.observe(sectionRef.current);
    }

    return () => observer.disconnect();
  }, []);

  return (
    <section ref={sectionRef} className="relative py-24">
      {/* Background with gradient and pattern */}
      <div className="absolute inset-0 bg-gradient-to-br from-[#2C3E50] via-[#1A1A1A] to-black">
        <div className="absolute inset-0 bg-[url('/patterns/dots.svg')] opacity-5" />
      </div>
      
      {/* Decorative elements */}
      <div className="absolute inset-0 overflow-hidden pointer-events-none">
        <div className="absolute -top-40 -right-40 w-[600px] h-[600px] bg-sky-500/10 rounded-full blur-[128px]" />
        <div className="absolute -bottom-40 -left-40 w-[600px] h-[600px] bg-teal-500/10 rounded-full blur-[128px]" />
      </div>

      {/* Content */}
      <div className="relative max-w-7xl mx-auto px-6">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
        >
          <div className="max-w-2xl mb-12">
            <h2 className="text-3xl font-bold text-white mb-4 tracking-tight">
              Listen On Your Favorite Platform
            </h2>
            <p className="text-zinc-400 leading-relaxed">
              Subscribe to our podcast on your preferred platform and never miss an episode. 
              Get notified about new releases and stay up to date with the latest insights.
            </p>
          </div>
          
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6">
            {platforms.map((platform, index) => (
              <PlatformCard 
                key={platform.name} 
                platform={platform} 
                index={index} 
              />
            ))}
          </div>
        </motion.div>
      </div>

      {/* Bottom gradient line */}
      <div className="absolute inset-x-0 bottom-0 h-px bg-gradient-to-r from-transparent via-sky-500/20 to-transparent" />
    </section>
  );
};

export default PlatformSection;