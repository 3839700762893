import React, { useRef, useEffect } from 'react';
import { motion } from 'framer-motion';
import ReactGA from 'react-ga4';

const ContentBlock = ({ title, content }) => (
  <motion.div
    initial={{ opacity: 0, y: 20 }}
    whileInView={{ opacity: 1, y: 0 }}
    viewport={{ once: true }}
    className="mb-8 last:mb-0"
  >
    <h3 className="text-xl font-bold text-white mb-4 tracking-tight">
      {title}
    </h3>
    <p className="text-zinc-400 leading-relaxed">
      {content}
    </p>
  </motion.div>
);

const SeoContentSection = () => {
  const sectionRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          ReactGA.event({
            category: 'Section View',
            action: 'SEO Content Section Visible',
            label: 'About Section'
          });
        }
      },
      { threshold: 0.2 }
    );

    if (sectionRef.current) {
      observer.observe(sectionRef.current);
    }

    return () => observer.disconnect();
  }, []);

  const contentBlocks = [
    {
      title: "The Premier Tech Innovation Podcast",
      content: "The Elon Musk Podcast stands as your premier source for in-depth analysis and discussions about Tesla, SpaceX, Neuralink, and the visionary behind these groundbreaking companies. With over 800 episodes, we deliver comprehensive coverage of the technological innovations shaping our future."
    },
    {
      title: "Expert Analysis & Insights",
      content: "Each episode features detailed analysis of company developments, technological breakthroughs, and market impacts. From Tesla's advancement in electric vehicles and autonomous driving to SpaceX's revolutionary space technology and Neuralink's neural interface innovations, we provide expert insights and thorough examination of each development."
    },
    {
      title: "Join Our Innovation Community",
      content: "Join our growing community of technology enthusiasts, investors, and innovators as we explore the companies and technologies that are shaping humanity's future. Subscribe to never miss an episode and stay informed about the latest developments in the world of technology and innovation."
    }
  ];

  return (
    <section ref={sectionRef} className="relative py-24">
      <div className="absolute inset-0 bg-gradient-to-b from-black via-black/95 to-black" />
      
      <div className="relative max-w-7xl mx-auto px-6">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          className="bg-white/[0.03] backdrop-blur-sm border border-white/10 rounded-2xl p-12"
        >
          <h2 className="text-3xl font-bold text-white mb-12 tracking-tight">
            About The Elon Musk Podcast
          </h2>

          <div className="space-y-12">
            {contentBlocks.map((block, index) => (
              <ContentBlock 
                key={block.title}
                title={block.title}
                content={block.content}
              />
            ))}
          </div>

          {/* Additional SEO-focused content */}
          <motion.div
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            viewport={{ once: true }}
            transition={{ delay: 0.2 }}
            className="mt-12 pt-12 border-t border-white/10"
          >
            <div className="grid md:grid-cols-2 gap-8">
              <div className="space-y-4">
                <h4 className="text-lg font-semibold text-white">
                  Featured Coverage
                </h4>
                <ul className="space-y-2 text-zinc-400">
                  <li>• Tesla Manufacturing & Innovation</li>
                  <li>• SpaceX Mars Mission Progress</li>
                  <li>• Neuralink Medical Breakthroughs</li>
                  <li>• X Platform Development</li>
                  <li>• The Boring Company Projects</li>
                </ul>
              </div>

              <div className="space-y-4">
                <h4 className="text-lg font-semibold text-white">
                  Expert Commentary
                </h4>
                <ul className="space-y-2 text-zinc-400">
                  <li>• Industry Analysis</li>
                  <li>• Technology Insights</li>
                  <li>• Market Impact Studies</li>
                  <li>• Future Predictions</li>
                  <li>• Innovation Tracking</li>
                </ul>
              </div>
            </div>
          </motion.div>
        </motion.div>
      </div>

      {/* Decorative Elements */}
      <div className="absolute inset-0 overflow-hidden pointer-events-none">
        <div className="absolute -top-40 -right-40 w-80 h-80 bg-white/[0.02] rounded-full blur-3xl" />
        <div className="absolute -bottom-40 -left-40 w-80 h-80 bg-white/[0.02] rounded-full blur-3xl" />
      </div>
    </section>
  );
};

export default React.memo(SeoContentSection);