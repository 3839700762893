import React, { Suspense, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import { Mic, Play, Clock, ArrowRight, Users, MessageSquare, ChevronRight } from 'lucide-react';
import ReactGA from 'react-ga4';
import AudioPlayer from '../AudioPlayer';

const stripHtml = html => {
  if (!html) return '';
  const tmp = document.createElement('DIV');
  tmp.innerHTML = html;
  return tmp.textContent || tmp.innerText || '';
};

const formatTime = time => {
  const minutes = Math.floor(time / 60);
  const seconds = Math.floor(time % 60);
  return `${minutes}:${seconds.toString().padStart(2, '0')}`;
};

const StatsCard = () => (
  <div className="mb-16">
    <div className="grid grid-cols-1 md:grid-cols-4 gap-6">
      <div className="group relative">
        <div className="absolute inset-0 bg-gradient-to-br from-[#3384ff]/20 to-transparent rounded-xl blur-xl group-hover:blur-2xl transition-all duration-300 opacity-50"></div>
        <div className="relative bg-black/40 backdrop-blur-sm border border-white/5 rounded-xl px-5 py-6 hover:border-[#3384ff]/20 transition-colors duration-300">
          <div className="flex items-center gap-3 mb-4">
            <div className="w-12 h-12 rounded-xl bg-[#3384ff]/10 flex items-center justify-center">
              <svg className="w-6 h-6 text-[#3384ff]" viewBox="0 0 24 24" fill="currentColor">
                <path d="M12.5 2c-4.5 1.5-7 5.5-7 10.5V15l-2 2v1h18v-1l-2-2v-2.5c0-5-2.5-9-7-10.5V2h-2v2.5z"/>
              </svg>
            </div>
            <span className="text-3xl font-bold text-white tracking-tight">500+</span>
          </div>
          <p className="text-sm text-zinc-400 font-medium tracking-wide whitespace-nowrap">SpaceX Updates</p>
        </div>
      </div>

      <div className="group relative">
        <div className="absolute inset-0 bg-gradient-to-br from-[#e82127]/20 to-transparent rounded-xl blur-xl group-hover:blur-2xl transition-all duration-300 opacity-50"></div>
        <div className="relative bg-black/40 backdrop-blur-sm border border-white/5 rounded-xl px-5 py-6 hover:border-[#e82127]/20 transition-colors duration-300">
          <div className="flex items-center gap-3 mb-4">
            <div className="w-12 h-12 rounded-xl bg-[#e82127]/10 flex items-center justify-center">
              <svg className="w-6 h-6 text-[#e82127]" viewBox="0 0 24 24" fill="currentColor">
                <path d="M12 4L4 7v6c0 5.55 3.84 10.74 8 12 4.16-1.26 8-6.45 8-12V7l-8-3z"/>
              </svg>
            </div>
            <span className="text-3xl font-bold text-white tracking-tight">300+</span>
          </div>
          <p className="text-sm text-zinc-400 font-medium tracking-wide whitespace-nowrap">Tesla Analysis</p>
        </div>
      </div>

      <div className="group relative">
        <div className="absolute inset-0 bg-gradient-to-br from-purple-500/20 to-transparent rounded-xl blur-xl group-hover:blur-2xl transition-all duration-300 opacity-50"></div>
        <div className="relative bg-black/40 backdrop-blur-sm border border-white/5 rounded-xl px-5 py-6 hover:border-purple-500/20 transition-colors duration-300">
          <div className="flex items-center gap-3 mb-4">
            <div className="w-12 h-12 rounded-xl bg-purple-500/10 flex items-center justify-center">
              <svg className="w-6 h-6 text-purple-500" viewBox="0 0 24 24" fill="currentColor">
                <path d="M13 3c-4.97 0-9 4.03-9 9H1l3.89 3.89.07.14L9 12H6c0-3.87 3.13-7 7-7s7 3.13 7 7-3.13 7-7 7c-1.93 0-3.68-.79-4.94-2.06l-1.42 1.42C8.27 19.99 10.51 21 13 21c4.97 0 9-4.03 9-9s-4.03-9-9-9zm-1 5v5l4.25 2.52.77-1.28-3.52-2.09V8z"/>
              </svg>
            </div>
            <span className="text-3xl font-bold text-white tracking-tight">150+</span>
          </div>
          <p className="text-sm text-zinc-400 font-medium tracking-wide whitespace-nowrap">Tech Deep Dives</p>
        </div>
      </div>

      <div className="group relative">
        <div className="absolute inset-0 bg-gradient-to-br from-gray-500/20 to-transparent rounded-xl blur-xl group-hover:blur-2xl transition-all duration-300 opacity-50"></div>
        <div className="relative bg-black/40 backdrop-blur-sm border border-white/5 rounded-xl px-5 py-6 hover:border-gray-500/20 transition-colors duration-300">
          <div className="flex items-center gap-3 mb-4">
            <div className="w-12 h-12 rounded-xl bg-gray-500/10 flex items-center justify-center">
              <svg className="w-6 h-6 text-gray-500" viewBox="0 0 24 24" fill="currentColor">
                <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-1 17.93c-3.95-.49-7-3.85-7-7.93 0-.62.08-1.21.21-1.79L9 15v1c0 1.1.9 2 2 2v1.93zm6.9-2.54c-.26-.81-1-1.39-1.9-1.39h-1v-3c0-.55-.45-1-1-1H8v-2h2c.55 0 1-.45 1-1V7h2c1.1 0 2-.9 2-2v-.41c2.93 1.19 5 4.06 5 7.41 0 2.08-.8 3.97-2.1 5.39z"/>
              </svg>
            </div>
            <span className="text-3xl font-bold text-white tracking-tight">200+</span>
          </div>
          <p className="text-sm text-zinc-400 font-medium tracking-wide whitespace-nowrap">Industry News</p>
        </div>
      </div>
    </div>
  </div>
);

const DiscordCTA = () => {
  const handleDiscordClick = () => {
    ReactGA.event({
      category: 'Community',
      action: 'Discord Click',
      label: 'Hero Section'
    });
  };

  return (
    <div className="group relative">
      <div className="absolute inset-0 bg-gradient-to-br from-[#5865F2]/30 to-transparent rounded-xl blur-xl opacity-0 group-hover:opacity-50 transition-all duration-500" />
      <div className="relative bg-white/[0.03] backdrop-blur-sm border border-white/10 hover:border-[#5865F2]/30 rounded-xl p-8 transition-colors duration-300">
        <div className="flex items-start gap-6">
          <div className="w-12 h-12 rounded-xl bg-[#5865F2]/10 flex items-center justify-center">
            <MessageSquare className="w-6 h-6 text-[#5865F2]" />
          </div>
          <div className="flex-1">
            <h3 className="text-xl font-semibold text-white mb-3">Join Our Discord Community</h3>
            <p className="text-zinc-400 mb-6 text-lg">
              Connect with fellow enthusiasts discussing SpaceX launches, Tesla innovations, 
              and every breakthrough from Elon Musk's companies.
            </p>
            <div className="flex items-center gap-6 mb-8">
              <div className="flex items-center gap-3">
                <Users className="w-5 h-5 text-zinc-500" />
                <span className="text-zinc-400">5,000+ members</span>
              </div>
              <div className="flex items-center gap-3">
                <MessageSquare className="w-5 h-5 text-zinc-500" />
                <span className="text-zinc-400">Active daily discussions</span>
              </div>
            </div>
            <a
              href="https://discord.gg/Jj3yX2UCzN"
              target="_blank"
              rel="noopener noreferrer"
              onClick={handleDiscordClick}
              className="inline-flex items-center gap-3 px-8 py-4 bg-[#5865F2] text-white rounded-xl hover:bg-[#4752C4] transition-all duration-300 group-hover:translate-y-0 translate-y-0 hover:-translate-y-0.5 font-medium"
            >
              Join the Community
              <ArrowRight className="w-5 h-5 group-hover:translate-x-1 transition-transform" />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

const HeroSection = ({ episode }) => {
  const sectionRef = useRef(null);

  useEffect(() => {
    if (episode) {
      const observer = new IntersectionObserver(
        (entries) => {
          if (entries[0].isIntersecting) {
            ReactGA.event({
              category: 'Section View',
              action: 'Hero Section Visible',
              label: `Episode ${episode.episodeNumber}`
            });
          }
        },
        { threshold: 0.2 }
      );

      if (sectionRef.current) {
        observer.observe(sectionRef.current);
      }

      return () => observer.disconnect();
    }
  }, [episode]);

  if (!episode) return null;

  const cleanDescription = stripHtml(episode.description);

  const handlePlayClick = () => {
    ReactGA.event({
      category: 'Episode',
      action: 'Play',
      label: episode.title
    });
  };

  return (
    <section ref={sectionRef} className="relative bg-black overflow-hidden">
      {/* Enhanced Background Effects */}
      <div className="absolute inset-0">
        <div className="absolute inset-0 bg-[radial-gradient(ellipse_at_top_left,_var(--tw-gradient-stops))] from-blue-900/20 via-black to-black" />
        <div className="absolute inset-0 bg-[radial-gradient(ellipse_at_bottom_right,_var(--tw-gradient-stops))] from-purple-900/10 via-transparent to-transparent" />
        <div className="absolute inset-0 bg-[linear-gradient(to_right,_var(--tw-gradient-stops))] from-black via-transparent to-black opacity-80" />
        <div className="absolute top-0 left-0 right-0 h-px bg-gradient-to-r from-transparent via-white/15 to-transparent" />
      </div>
      
      <div className="relative max-w-7xl mx-auto px-6 py-20">
        <div className="grid lg:grid-cols-5 gap-16 items-start">
          {/* Content Column */}
          <motion.div
            className="lg:col-span-3"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8, ease: "easeOut" }}
          >
            {/* Value Proposition */}
            <div className="mb-12">
              <div className="inline-flex items-center px-3 py-1.5 rounded-full bg-[#3384ff]/10 backdrop-blur-sm border border-[#3384ff]/20 mb-6">
                <span className="text-sm font-medium bg-gradient-to-r from-[#3384ff] to-blue-400 bg-clip-text text-transparent">
                  The Ultimate Elon Musk Companies Podcast
                </span>
              </div>
              
              <h1 className="text-4xl sm:text-5xl lg:text-7xl font-bold mb-8 leading-[1.1] tracking-tight">
                <span className="bg-gradient-to-r from-white via-white to-white/70 bg-clip-text text-transparent">
                  Your Inside Look at Tesla, SpaceX, and Beyond
                </span>
              </h1>
              
              <p className="text-xl text-zinc-400 leading-relaxed max-w-2xl">
                With over <span className="text-white font-medium">75,000 monthly listeners</span>, 
                our daily show ranks as the <span className="text-white font-medium">#15 video podcast</span> and 
                a top 20 tech show, breaking down how SpaceX, Tesla, X, Neuralink, and The Boring Company 
                are building our tomorrow.
              </p>
            </div>

            <StatsCard />

            <DiscordCTA />
          </motion.div>

          {/* Image Column */}
          <motion.div
            className="lg:col-span-2"
            initial={{ opacity: 0, scale: 0.95 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.8, delay: 0.2 }}
          >
            <div className="sticky top-24 space-y-8">
              {/* Hero Image */}
              <div className="group relative">
                <div className="absolute inset-0 bg-gradient-to-br from-[#3384ff]/20 to-transparent rounded-2xl blur-xl opacity-0 group-hover:opacity-50 transition-all duration-500" />
                <div className="relative aspect-square rounded-2xl overflow-hidden bg-black shadow-2xl">
                  <img
                    src="/elon-musk-pod-thumb.jpg"
                    alt="Elon Musk Podcast"
                    className="w-full h-full object-cover opacity-90 hover:opacity-100 transition-all duration-700 transform group-hover:scale-[1.02]"
                    loading="eager"
                    decoding="async"
                  />
                  <div className="absolute inset-0 bg-gradient-to-br from-transparent via-black/20 to-black/60" />
                </div>
              </div>

              {/* Latest Episode Card */}
              <div className="group relative">
                <div className="absolute inset-0 bg-gradient-to-br from-white/[0.05] to-transparent rounded-xl blur-xl opacity-0 group-hover:opacity-50 transition-all duration-500" />
                <div className="relative bg-white/[0.03] backdrop-blur-sm border border-white/10 hover:border-white/20 rounded-xl p-8 transition-all duration-300">
                  <div className="flex items-center gap-4 mb-6">
                    <div className="px-4 py-2 rounded-full bg-white/10 border border-white/10 text-sm font-medium text-white">
                      Latest Episode #{episode.episodeNumber}
                    </div>
                    <div className="flex items-center gap-2 text-zinc-500">
                      <Clock className="w-4 h-4" />
                      <span>{formatTime(episode.duration)}</span>
                    </div>
                  </div>

                  <div className="space-y-4 mb-8">
                    <h3 className="text-2xl font-bold text-white">{episode.title}</h3>
                    <p className="text-zinc-400 line-clamp-2 text-lg leading-relaxed">
                      {cleanDescription}
                    </p>
                  </div>

                  <Suspense 
                    fallback={
                      <div className="h-24 bg-white/5 rounded-xl animate-pulse" />
                    }
                  >
                    <div 
                      onClick={handlePlayClick}
                      className="mb-8 transform transition-transform duration-300 hover:scale-[1.01]"
                    >
                      <AudioPlayer 
                        src={episode.audioUrl} 
                        title={episode.title}
                        duration={episode.duration}
                      />
                    </div>
                  </Suspense>

                  <div className="pt-6 border-t border-white/10">
                    <Link 
                      to="/episodes"
                      className="group/link flex items-center justify-between px-6 py-4 bg-white/5 text-white rounded-xl hover:bg-white/10 transition-all duration-300"
                    >
                      <span className="font-medium">Explore All Episodes</span>
                      <div className="h-8 w-8 rounded-full bg-white/10 flex items-center justify-center transition-all duration-300 group-hover/link:bg-white/20">
                        <ChevronRight className="w-5 h-5 group-hover/link:translate-x-0.5 transition-transform duration-300" />
                      </div>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </motion.div>
        </div>
      </div>
    </section>
  );
};

export default React.memo(HeroSection);