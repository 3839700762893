import { useRef, useEffect } from 'react';
import { motion } from 'framer-motion';
import { Users, Headphones, Radio, Star } from 'lucide-react';
import CountUp from 'react-countup';
import ReactGA from 'react-ga4';

const StatCard = ({ title, value, icon: Icon, suffix = '', prefix = '' }) => {
  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      whileInView={{ opacity: 1, y: 0 }}
      viewport={{ once: true }}
      transition={{ duration: 0.5 }}
      className="group relative bg-white/[0.03] hover:bg-white/[0.05] backdrop-blur-sm border border-white/10 rounded-xl p-8 transition-all duration-300"
    >
      <div className="relative flex items-center gap-4">
        <div className="w-12 h-12 rounded-lg bg-blue-500/10 flex items-center justify-center group-hover:bg-blue-500/20 transition-colors">
          <Icon className="w-6 h-6 text-blue-400" />
        </div>
        <div>
          <div className="text-2xl font-bold text-white mb-1">
            {prefix}
            <CountUp end={value} duration={2.5} separator="," />
            {suffix}
          </div>
          <div className="text-blue-200/80 text-sm">{title}</div>
        </div>
      </div>
    </motion.div>
  );
};

const StatisticsSection = ({ totalEpisodes }) => {
  const sectionRef = useRef(null);

  const stats = [
    { title: 'Episodes Released', value: 850, icon: Radio, suffix: '+' }, // Fixed at 850+
    { title: 'Monthly Listeners', value: 75000, icon: Users, suffix: '+' },
    { title: 'Total Downloads', value: 3200000, icon: Headphones, suffix: '+' },
    { title: 'Average Rating', value: 4.8, icon: Star }
  ];

  return (
    <section ref={sectionRef} className="relative py-24">
      {/* Background with gradient and pattern */}
      <div className="absolute inset-0 bg-gradient-to-b from-black via-[#0A1930] to-black">
        <div className="absolute inset-0 bg-[url('/grid-pattern.svg')] opacity-10" />
      </div>
      
      {/* Decorative elements */}
      <div className="absolute inset-0 overflow-hidden">
        <div className="absolute top-0 right-0 w-[500px] h-[500px] bg-blue-500/10 rounded-full blur-3xl" />
        <div className="absolute bottom-0 left-0 w-[500px] h-[500px] bg-indigo-500/10 rounded-full blur-3xl" />
      </div>

      {/* Content */}
      <div className="relative max-w-7xl mx-auto px-6">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          className="mb-12"
        >
          <h2 className="text-3xl font-bold text-white mb-4">
            Growing Community
          </h2>
          <p className="text-blue-200/80 max-w-2xl">
            Join our daily conversations about the latest SpaceX launches, Tesla breakthroughs, and major updates from Elon Musk.
          </p>
        </motion.div>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
          {stats.map((stat) => (
            <StatCard key={stat.title} {...stat} />
          ))}
        </div>
      </div>

      {/* Section divider */}
      <div className="absolute inset-x-0 bottom-0 h-px bg-gradient-to-r from-transparent via-blue-500/20 to-transparent" />
    </section>
  );
};

export default StatisticsSection;