const isDevelopment = window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1';

export const SITE_CONFIG = {
  title: 'Elon Musk Podcast',
  description: 'Your premier source for in-depth analysis and discussions about Tesla, SpaceX, Neuralink, and the visionary behind them. Join us as we explore the companies and innovations shaping humanity\'s future.',
  baseUrl: isDevelopment ? 'http://localhost:3000' : 'https://elonmuskpodcast.com',
  defaultImage: '/podcast-cover.jpg',
  socialLinks: {
    twitter: 'https://twitter.com/elonmuskpodcast',
    linkedin: 'https://linkedin.com/company/elonmuskpodcast'
  }
};